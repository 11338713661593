import(/* webpackMode: "eager", webpackExports: ["TabContent"] */ "/app/node_modules/@ark-ui/react/dist/components/tabs/tab-content.js");
;
import(/* webpackMode: "eager", webpackExports: ["TabIndicator"] */ "/app/node_modules/@ark-ui/react/dist/components/tabs/tab-indicator.js");
;
import(/* webpackMode: "eager", webpackExports: ["TabList"] */ "/app/node_modules/@ark-ui/react/dist/components/tabs/tab-list.js");
;
import(/* webpackMode: "eager", webpackExports: ["TabTrigger"] */ "/app/node_modules/@ark-ui/react/dist/components/tabs/tab-trigger.js");
;
import(/* webpackMode: "eager", webpackExports: ["TabsContext"] */ "/app/node_modules/@ark-ui/react/dist/components/tabs/tabs-context.js");
;
import(/* webpackMode: "eager", webpackExports: ["TabsRootProvider"] */ "/app/node_modules/@ark-ui/react/dist/components/tabs/tabs-root-provider.js");
;
import(/* webpackMode: "eager", webpackExports: ["TabsRoot"] */ "/app/node_modules/@ark-ui/react/dist/components/tabs/tabs-root.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/flag-icons/css/flag-icons.min.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["ArticleFilterHeader"] */ "/app/src/components/block-templates/ArticleFilterBlockTemplate/components/ArticleFilterHeader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ArticleFilterResults"] */ "/app/src/components/block-templates/ArticleFilterBlockTemplate/components/ArticleFilterResults.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ContentSearchField"] */ "/app/src/components/block-templates/ContentSearchBlockTemplate/components/ContentSearchField.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ContentSearchResults"] */ "/app/src/components/block-templates/ContentSearchBlockTemplate/components/ContentSearchResults.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/block-templates/Forms/FormContainerBlockTemplate/components/Form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PensionCalculator"] */ "/app/src/components/block-templates/PensionCalculatorBlockTemplate/components/PensionCalculator/PensionCalculator.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PortfolioSimulator"] */ "/app/src/components/block-templates/PortfolioSimulatorBlockTemplate/components/PortfolioSimulator.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/core/Accordion/components/AccordionItem.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/core/Accordion/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/core/Button/Button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AppBanner"] */ "/app/src/components/shared/AppBanner/AppBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Disclaimer"] */ "/app/src/components/shared/Disclaimer/Disclaimer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ExecutionQualityReportItem"] */ "/app/src/components/shared/ExecutionQualityReport/ExecutionQualityReportItem.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FilterLocationWrapper"] */ "/app/src/components/shared/filters/FilterLocationWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FooterInternationalSection"] */ "/app/src/components/shared/Footer/components/FooterInternationalSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FooterLegalLink"] */ "/app/src/components/shared/Footer/components/FooterLegalLink.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/shared/Footer/components/FooterLinkBlock.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FormFieldUpload"] */ "/app/src/components/shared/Forms/FileUploadFormField/FileUploadFormField.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HiddenFormField"] */ "/app/src/components/shared/Forms/HiddenFormField.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HiddenHoneyPotFormField"] */ "/app/src/components/shared/Forms/HiddenHoneyPotFormField.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SelectFormField"] */ "/app/src/components/shared/Forms/SelectFormField.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TextFormField"] */ "/app/src/components/shared/Forms/TextFormField.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GlobalTrackingProvider"] */ "/app/src/components/shared/GlobalTrackingContainer/GlobalTrackingContainer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderContainer"] */ "/app/src/components/shared/Header/components/HeaderContainer/HeaderContainer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderProvider"] */ "/app/src/components/shared/Header/components/HeaderProvider/HeaderProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Jobs"] */ "/app/src/components/shared/Jobs/Jobs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LanguageSelect"] */ "/app/src/components/shared/language/LanguageSelect.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LoginNavigation"] */ "/app/src/components/shared/LoginNavigation/LoginNavigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LoginNavigationProvider"] */ "/app/src/components/shared/LoginNavigation/LoginNavigationProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Navigation"] */ "/app/src/components/shared/Navigation/Navigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PreviewModeBanner"] */ "/app/src/components/shared/PreviewModeBanner/PreviewModeBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SearchInput"] */ "/app/src/components/shared/searchInput/SearchInput.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Copy"] */ "/app/src/components/shared/Share/components/Copy.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Tabs"] */ "/app/src/components/shared/Tabs/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TealiumUniversalDataProvider"] */ "/app/src/components/shared/TealiumUniversalDataProvider/TealiumUniversalDataProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TextSnippetTracked"] */ "/app/src/components/shared/TextSnippet/components/TextSnippetTracked/TextSnippetTracked.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CookieSettingsTextSnippetTrigger"] */ "/app/src/components/shared/TextSnippet/CookieSettingsTextSnippetTrigger.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/shared/TrackedContainer/TrackedContainer.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/shared/tracking/Tracked.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VimeoPlayer"] */ "/app/src/components/shared/VimeoPlayer/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WealthCheck"] */ "/app/src/components/shared/WealthCheck/WealthCheck.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/icons/ArrowRightIcon.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/icons/DownIcon.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/icons/MinusIcon.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/icons/PlusIcon.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NoSSR"] */ "/app/src/utils/NoSSR.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PageContextProvider"] */ "/app/src/utils/pageContextProvider.tsx");
