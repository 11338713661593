'use client';
import { Button } from '@/components/core/Button/Button';
import { CopyIcon } from '@/icons/CopyIcon';
import { ITranslatableComponentProps } from '@/interfaces/ITranslatableComponentProps';
import { IMetadata } from '@/interfaces/page';
import { ButtonVariants } from '@/styles/theme/button';
import { translate } from '@/utils/i18n/translation-labels/translationLabels';

interface ShareProps extends ITranslatableComponentProps {
	buttonIconClassName: string;
	buttonProps: Partial<ButtonVariants>;
	metadata: IMetadata;
}

export const Copy = ({ buttonIconClassName, buttonProps, metadata, translations = {} }: ShareProps) => {
	const share = async () => {
		if (typeof window !== 'undefined' && 'share' in window.navigator) {
			navigator.share({
				title: metadata.title,
				text: metadata.description,
				url: metadata.pageUrl,
			});
		} else {
			try {
				await navigator.clipboard.writeText(metadata.pageUrl || '');
			} catch (err) {
				console.error('Failed to copy: ', err);
			}
		}
	};

	return (
		<Button
			title={translate(translations, 'share.copy')}
			onClick={share}
			leftIcon={<CopyIcon className={buttonIconClassName} />}
			{...buttonProps}
		/>
	);
};
