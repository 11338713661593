'use client';

import { Button } from '@/components/core/Button/Button';
import { Checkbox } from '@/components/core/Checkbox';
import { Heading } from '@/components/core/Heading/Heading';
import { ISearchDropdownItem, SearchDropdown } from '@/components/core/SearchDropdown/SearchDropdown';
import { Text } from '@/components/core/Text/Text';
import { ButtonsToHide, IDisclaimerFunctionality, IDisplaySettings } from '@/components/shared/Disclaimer/Disclaimer';
import {
	DisclaimerOptions,
	convertToCountry,
	getCountries,
	getDefaultSelectedDomicile,
	getVisitorRoles,
} from '@/components/shared/Disclaimer/Disclaimer.utils';
import { IUseDisclaimerCacheReturnType } from '@/components/shared/Disclaimer/components/useDisclaimerCache';
import { useGlobalTracking } from '@/components/shared/GlobalTrackingContainer/useGlobalTracking';
import { TextSnippet } from '@/components/shared/TextSnippet/TextSnippet';
import { changeMarketToNewCountry } from '@/components/shared/language/MarketSelect.utils';
import { BackgroundColorVariant } from '@/enums/ColorMode';
import { CountryCode } from '@/enums/Language';
import { useCulture } from '@/hooks/useCulture';
import { IPageLanguage } from '@/interfaces/coreInformation';
import { css, cx } from '@/styled-system/css';
import { Flex, VStack } from '@/styled-system/jsx';
import { disclaimer } from '@/styles/theme/disclaimer';
import { IDisclaimerSubmitBody } from '@/types/disclaimer-submission/IDisclaimerSubmitBody';
import { ILanguageSettings } from '@/utils/language';
import { UserDeviceType, PageContext } from '@/utils/pageContextProvider';
import { type CheckboxCheckedChangeDetails } from '@ark-ui/react';
import { usePathname, useRouter } from 'next/navigation';
import { FC, useContext, useEffect, useState } from 'react';

interface ILegalDisclaimerProps {
	displaySettings: IDisplaySettings;
	disclaimerFunctionality: IDisclaimerFunctionality;
	selectedCountry?: string;
	displayedTogether?: boolean;
	setIsOpen: (isOpen: boolean) => void;
	translations: Record<string, string>;
	customTermsCookieKey: string | undefined;
	onChange?: (value: IDisclaimerSubmitBody) => void;
	onSubmit?: () => void;
	existingLanguages: IPageLanguage[];
	languageSettings: ILanguageSettings;
	disabled?: boolean;
	className?: string;
	disclaimerCache: IUseDisclaimerCacheReturnType;
}

export const LegalDisclaimer: FC<ILegalDisclaimerProps> = ({
	displaySettings,
	disclaimerFunctionality,
	selectedCountry,
	setIsOpen,
	translations,
	onChange,
	onSubmit,
	existingLanguages,
	languageSettings,
	disabled,
	className,
	disclaimerCache,
}) => {
	const {
		disclaimerTitleText,
		disclaimerConsentSectionText,
		hideVisitorDomicileAndRole,
		countriesListForVisitorDomicile,
		linkTermsAndConditions,
	} = displaySettings;
	const router = useRouter();
	const currentPath = usePathname();
	const cultureInstance = useCulture({ existingLanguages });
	const { userDeviceType } = useContext(PageContext);
	const { trackingSettings } = useGlobalTracking();
	const { country, language } = cultureInstance;
	const { cachedValues, updateCache } = disclaimerCache;

	const [isUSCitizen, setIsUSCitizen] = useState<boolean>(() => Boolean(cachedValues?.isUSCitizen));
	const [selectedDomicile, setSelectedDomicile] = useState<ISearchDropdownItem | undefined>(
		() => cachedValues?.selectedDomicile
	);

	const visitorRoles =
		!hideVisitorDomicileAndRole &&
		trackingSettings?.tealiumSettings?.keyValueCollection &&
		trackingSettings?.tealiumSettings?.keyValueCollection?.length > 0 &&
		getVisitorRoles(trackingSettings.tealiumSettings.keyValueCollection, translations);

	const cachedSelectedVisitorRole = cachedValues?.selectedvisitorRole?.value;
	const defaultVisitorRole =
		visitorRoles && cachedSelectedVisitorRole
			? visitorRoles.find((role) => role.value === cachedSelectedVisitorRole)
			: undefined;

	const [selectedvisitorRole, setSelectedVisitorRole] = useState<ISearchDropdownItem | undefined>(
		() => defaultVisitorRole
	);

	const buttonsToHide = displaySettings?.buttonsToHide?.split(',');
	const disclaimerOptions = disclaimerFunctionality?.disclaimerOptions?.split(',');

	const handleSelectDomicile = async (item?: ISearchDropdownItem) => {
		setSelectedDomicile(item);
		updateCache({ isUSCitizen, selectedDomicile: item, selectedvisitorRole });

		onChange &&
			onChange({
				Domicile: item?.value,
				IsUSCitizen: isUSCitizen,
				VisitorRole: selectedvisitorRole?.value,
			});

		if (disclaimerOptions?.includes(DisclaimerOptions.RedirectWhenVisitorSelectsDomicile)) {
			if (!isUSCitizen || country.toLowerCase() !== CountryCode.US) {
				changeMarketToNewCountry(
					convertToCountry(item),
					cultureInstance,
					language,
					country,
					existingLanguages,
					languageSettings
				);
			}
		}

		if (disclaimerOptions?.includes(DisclaimerOptions.ReloadWhenVisitorChangesDomicileOrRole)) {
			// Reload page when visitor changes domicile
			router.refresh();
		}
	};

	const handleSelectVisitorRole = async (item?: ISearchDropdownItem) => {
		setSelectedVisitorRole(item);
		updateCache({ isUSCitizen, selectedDomicile, selectedvisitorRole: item });

		onChange &&
			onChange({
				Domicile: selectedDomicile?.value,
				IsUSCitizen: isUSCitizen,
				VisitorRole: item?.value,
			});

		if (disclaimerOptions?.includes(DisclaimerOptions.ReloadWhenVisitorChangesDomicileOrRole)) {
			// Reload page when visitor changes role
			router.refresh();
		}
	};

	const handleUsCitizenToggle = async (details: CheckboxCheckedChangeDetails) => {
		setIsUSCitizen(details.checked === true);
		updateCache({ isUSCitizen: details.checked === true, selectedDomicile, selectedvisitorRole });

		if (details.checked !== true && selectedDomicile?.value?.toLowerCase() !== CountryCode.US) {
			changeMarketToNewCountry(
				convertToCountry(selectedDomicile),
				cultureInstance,
				language,
				country,
				existingLanguages,
				languageSettings
			);
		}

		onChange &&
			onChange({
				Domicile: selectedDomicile?.value,
				IsUSCitizen: details.checked === true,
				VisitorRole: selectedvisitorRole?.value,
			});

		if (disclaimerOptions?.includes(DisclaimerOptions.ReloadWhenVisitorChangesDomicileOrRole)) {
			// Reload page when visitor changes role
			router.refresh();
		}
	};

	const handleConfirm = async () => {
		onSubmit && onSubmit();
		setIsOpen(false);
	};

	const handleDecline = async () => {
		const redirectUrl = disclaimerFunctionality.declineButtonRedirectLink;

		if (redirectUrl) {
			if (redirectUrl === currentPath) {
				location.reload();
			} else {
				router.push(redirectUrl);
			}
		}
		setIsOpen(false);
	};

	const classes = disclaimer();

	const countrySelectItems = getCountries(countriesListForVisitorDomicile ?? [], translations);
	const defaultSelectItem = getDefaultSelectedDomicile(
		countrySelectItems,
		selectedCountry ?? country,
		cachedValues?.selectedDomicile
	);

	useEffect(() => {
		const updatedValues = {
			Domicile: selectedDomicile?.value,
			IsUSCitizen: isUSCitizen,
			VisitorRole: selectedvisitorRole?.value,
		};

		if (!isUSCitizen && country === CountryCode.US && !cachedValues) {
			setIsUSCitizen(true);
			updatedValues.IsUSCitizen = true;
		}

		if (!selectedDomicile && !cachedValues) {
			setSelectedDomicile(defaultSelectItem);
			updatedValues.Domicile = defaultSelectItem?.value;
		}

		onChange?.(updatedValues);
	}, []);

	return (
		<VStack className={cx(classes.legalDisclaimer, className)}>
			<VStack className="Introduction">
				<Heading as="p" type="h5" textAlign="left">
					{disclaimerTitleText}
				</Heading>
				{disclaimerConsentSectionText && (
					<TextSnippet
						id="disclaimerConsentSectionText"
						content={disclaimerConsentSectionText}
						parentBackgroundColor={BackgroundColorVariant.PRIMARY}
						shouldBindCookieSettingsTrigger={false}
						dataId="disclaimer-consent-section-text"
					/>
				)}
			</VStack>
			<Flex className="Selects">
				{!hideVisitorDomicileAndRole && (
					<Flex className="Place" direction="column" w="full">
						{countriesListForVisitorDomicile && (
							<SearchDropdown
								label={translations['disclaimer.label.visitordomicile']}
								placeholder={translations['disclaimer.placeholder.visitordomicile']}
								noResultsLabel={translations['dropdown.noResults']}
								onChange={({ selectedItem }) => handleSelectDomicile(selectedItem)}
								items={countrySelectItems}
								value={selectedDomicile ? [selectedDomicile.value] : []}
								controlStyleProps={css.raw({ backgroundColor: 'surface.interactive.transparent.regular.default' })}
								contentStyleProps={css.raw({ maxH: { base: '200px', md: '400px' } })}
								showClearTrigger={false}
								usePortal={userDeviceType === UserDeviceType.Mobile ? false : true}
							/>
						)}
						{(disclaimerFunctionality.usaSelectionRedirectLink || country.toLowerCase() === CountryCode.US) && (
							<Checkbox
								label={translations['disclaimer.label.uscitizen']}
								isChecked={isUSCitizen}
								checkboxValue="us-citizen"
								defaultValue={isUSCitizen ? 'us-citizen' : ''}
								onChange={(event) => handleUsCitizenToggle({ checked: event.target.value?.indexOf('us-citizen') >= 0 })}
							/>
						)}
					</Flex>
				)}
				<Flex direction="column" w="full">
					{visitorRoles && (
						<SearchDropdown
							ids={{ input: 'visitor-role-dropdown' }}
							label={translations['disclaimer.label.visitorrole']}
							placeholder={translations['disclaimer.placeholder.visitorrole']}
							noResultsLabel={translations['dropdown.noResults']}
							items={visitorRoles}
							onChange={({ selectedItem }) => handleSelectVisitorRole(selectedItem)}
							value={selectedvisitorRole ? [selectedvisitorRole.value] : []}
							controlStyleProps={css.raw({ backgroundColor: 'surface.interactive.transparent.regular.default' })}
							readOnlyInput={true}
							showClearTrigger={false}
							usePortal={userDeviceType === UserDeviceType.Mobile ? false : true}
						/>
					)}
					<Text className="Terms">
						{linkTermsAndConditions && (
							<a href={linkTermsAndConditions}>{translations['disclaimer.link.terms.text']}</a>
						)}
					</Text>
				</Flex>
			</Flex>
			<Flex className="Buttons">
				{!buttonsToHide?.includes(ButtonsToHide.ACCEPT) && (
					<Button
						id="accept-terms-button"
						onClick={handleConfirm}
						variant="primary"
						size="large"
						title={translations['disclaimer.button.accept.title']}
						disabled={disabled}
					>
						{translations['disclaimer.button.accept.text']}
					</Button>
				)}
				{!buttonsToHide?.includes(ButtonsToHide.DECLINE) && (
					<Button
						onClick={handleDecline}
						variant="secondary"
						size="large"
						title={translations['disclaimer.button.decline.title']}
						disabled={disabled}
					>
						{translations['disclaimer.button.decline.text']}
					</Button>
				)}
			</Flex>
		</VStack>
	);
};
